.contact{
  
}
.contactus-container{
  
  padding: 50px 0;

}
.contactus-msg{
  padding: 50px 0;
  max-width: 1280px;
  margin: 0 auto;
}
.contactus-msg h2,
.contact-us-title{
  text-align: center;
  font-size: 42px;
  font-weight: normal;
  font-style: normal;
  color: #191919;
}
.contactus-msg-detail{
  display: flex;
  padding-top: 30px;
}
.contactus-msg-detail>div{
  width: 33%;
  text-align: center;
}

.contact-us{
  padding: 50px 0;
  background-color: #f7f7f7;
}
.container{
  max-width: 1280px;
  margin: 0 auto;
}


input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  display: inline-block;
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 768px){
  .styled-input {
      width:100%;
  }
}

.styled-input label {
  color: #999;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide { 
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #2d2d2d;
  color: white;
  border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 15em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 20px auto 25px auto;
}
.btn-container{
  text-align: right;
}

.submit-btn {
  font-size: 16px;
  letter-spacing: 1px;
  text-indent: 1px;
  padding: 15px 35px;
  border-width: 2px;
  border-color: rgb(240, 67, 125);
  background-color: rgb(240, 67, 125);
  color: rgb(255, 255, 255);
  border-radius: 30px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06),
            0 2px 10px 0 rgba(0,0,0,0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-decoration: none;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
            0 1px 1px 0 rgba(0,0,0,0.09);
  opacity: 0.7;
}

@media (max-width: 768px) {
  .submit-btn {
      width:100%;
      text-align:center;
  }
}

input[type=checkbox] + label {
color: #ccc;
font-style: italic;
} 

input[type=checkbox]:checked + label {
color: #f00;
font-style: normal;
}
