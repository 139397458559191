.home {

}
.our-goal-container{
  background: #f7f7f7;
}
.our-goal,
.good-points,
.our-advantage,
.team-member{
  margin: 0 auto;
  padding: 50px 0;
  max-width: 1280px;
}

.our-goal{
  text-align: center;
}

.our-goal h3{
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  color: #0b1b2b;
}
.our-goal p{
  letter-spacing: 2px; 
  line-height: 30px;
  font-size: 18px;
}
/* good-points */
.good-points{
  display: flex;
  text-align: center;
}
.good-points div{
  width: 33%;
}
.good-points div svg{
  fill: #35d7a4;
}
.good-points h3{
  font-weight: 400;
  margin: 5px ;
  color: #191919;
  font-size: 22px;
}
.good-points div p{
  
}
.our-advantage{
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.our-advantage-img-pc{
  margin-right: 50px;
}
.our-advantage-img-mobile{
  margin-left: 50px;
}
/* our team */
.our-team-container{
  background: #f7f7f7;
}
.our-team-container>h3{
  padding-top: 50px;
  text-align: center;
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  color: #0b1b2b;
}
.team-member{
  display: flex;
}
.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: #f7f7f7;
  text-align: center;
  overflow: hidden;
  position: relative;
  width: 400px;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #35d7a4;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color:  #35d7a4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f7f7;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color:  #35d7a4;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social li a:hover {
  color:  #35d7a4;
  background-color: #f7f5ec;
}

.our-advantage-content p {
  line-height: 30px;
}