.nav-container{
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  color: #141414;
}
.logo{
  display: flex;
  align-items: center;

}
.logo h1{
  font-size: 26px;
}
.nav-links{
  display: flex;
}
.nav-links a{
  display: inline-block;
  width: 100px;
  text-align: center;
  color: #141414;
  text-decoration: none;
  font-weight: bold;
}