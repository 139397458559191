
.banner-container{
  background: url(http://lc-mzK8jKT8.cn-n1.lcfile.com/lK0i1zNSC7hlSecNFvRwfNJBxP57lXIU/bg.jpeg) no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
  height: 600px;
  position: relative;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(53,215,164,0.9);
}
.banner-content{
  display: flex;
  width: 1280px;
  margin: 50px auto;
}
.banner-text{ 
  margin-top: 100px;
  color: #fff;
  max-width: 600px;
}
.banner-text h1{
  font-size: 74px;
  line-height:74px;  
  font-weight: 700;
  letter-spacing: -2px;
}
.banner-text p{
  margin: 20px 0;
  font-size: 18px;
}
.banner-text a{
  display: block;
  margin-top:10px;
  font-size: 16px;
  letter-spacing: 1px;
  text-indent: 1px;
  padding: 15px 35px;
  border-width: 2px;
  border-color: rgb(240, 67, 125);
  background-color: rgb(240, 67, 125);
  color: rgb(255, 255, 255);
  border-radius: 30px;
  text-decoration: none;
  width: 70px;
}
.banner-img{
  margin-left: 50px;
  vertical-align: middle;
  animation-name: up-down;
  animation-duration: 1s; 
  animation-delay: 1s;
  visibility: visible;
}


@keyframes up-down {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}
100% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
}