.aboutus{
  background-color: #f7f7f7;
}
.aboutus-container{
  display: flex;
  margin: 0 auto;
  padding: 50px 0;
  max-width: 1280px;
  align-items: center;
}
.aboutus-text{
  margin-left: 100px;
  width: 650px;
}
.aboutus-text p{
  max-width: 450px;
  line-height: 28px;
}
.aboutus-container img{
  display: block;
  /* align-items: center; */
  vertical-align: middle;
}