.starups{
  align-items: start;
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(1,1fr);
  margin: 60px 0 0;
  padding-bottom: 60px;
  padding-top: 60px;
  width: 100%;
  gap: 80px 90px;
  grid-template-columns: repeat(2,1fr);
  margin: 120px 0 0;
  padding-bottom: 120px;
}
h2{
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -.85px;
  line-height: 54px;
}
.content{
  letter-spacing: -.05em;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin: 0 0 20px;
}
.home-block-link{
  display: block;
  /* border-bottom: 3px solid transparent; */
  /* box-shadow: 0 3px rgb(0 0 0 / 16%); */
  text-decoration: none;
  transition: all .3s;
  color: hsla(0,0%,8%,.56);
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -.5px;
  line-height: 36px;
}