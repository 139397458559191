.footer{
  display: flex;
  margin: 0 auto;
  padding: 100px 0;
  max-width: 1280px;
  color:rgb(133, 141, 149);
}
.footer>div{
  width: 360px;
}
.beian{
  color: rgb(133, 141, 149);
  text-decoration: none;
}
.beian:hover{
  opacity: .8;
}